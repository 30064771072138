<template>
  <div class="footer">
    <div class="left align-left">
      <div>
        <p>hello@ragnalabs.com</p>
        <p>©2013—2025 Ragna Labs®</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
